import CartIcon from "@material-ui/icons/ShoppingCartRounded";
import { Tooltip } from "@my-scoot/component-library-legacy";
import React from "react";
import { useDispatch } from "react-redux";
import { useCart } from "ui/pages/Account/hooks/useCart";
import { EXLY_ACCOUNT__GET_CART } from "ui/pages/Account/redux/actions";

export const AppbarCartAction = () => {
  const dispatch = useDispatch();
  const { getCartItemsCount, handleGoToCart } = useCart();
  const cartCount = getCartItemsCount();

  React.useEffect(() => {
    dispatch({ type: EXLY_ACCOUNT__GET_CART, forceUpdate: true });
  }, []);

  if (cartCount === 0) return null;

  return (
    <Tooltip title="Cart" color="primary" arrow>
      <div className="tw-mr-4 tw-cursor-pointer" onClick={handleGoToCart}>
        <div className="tw-relative">
          <CartIcon className="!tw-text-[28px] max-md:!tw-text-2xl !tw-text-[rgb(110_103_94)] max-md:!tw-text-white" />
          <div className="tw-absolute tw-top-0 tw-right-0 tw-bg-[#DF645D] tw-rounded-full tw-py-[2.37px] tw-px-[4.75px] tw-text-white tw-text-[10px] tw-leading-[normal]">
            {cartCount}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
