import Button from "@material-ui/core/Button";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import React from "react";
import { useCart } from "ui/pages/Account/hooks/useCart";
import { pluralise } from "utils/Utils";

export const CartStatusBar = () => {
  const { getCartItemsCount, handleGoToCart } = useCart();
  const totalItemsInCart = getCartItemsCount();

  if (!totalItemsInCart) return null;

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-mb-6 tw-px-4 tw-py-3 tw-rounded-[12px] tw-border tw-border-[#7365CD] tw-border-solid tw-bg-[#E9E4F6]">
      <div className="tw-text-[#1C274C] tw-text-sm tw-leading-[normal]">
        <LocalMallRoundedIcon className="!tw-text-[#7365CD] !tw-text-xl !tw-mr-1 !tw-mt-[-3px]" />{" "}
        <strong>{totalItemsInCart}</strong>
        {` ${pluralise("item", totalItemsInCart)} added to cart`}
      </div>

      <Button
        startIcon={<ShoppingCartOutlinedIcon />}
        className="!tw-min-w-[200px] !tw-rounded-lg tw-bg-[radial-gradient(839.49%_87.11%_at_50%_50%,_#7365CD_0%,_#40329A_100%)] !tw-px-4 !tw-py-3 !tw-text-white !tw-normal-case !tw-text-base !tw-leading-[normal] !tw-font-medium max-md:!tw-w-full"
        onClick={handleGoToCart}
      >
        Go to Cart
      </Button>
    </div>
  );
};
