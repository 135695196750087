import {
  LINK_TYPE_ICONS,
  LINK_TYPES,
} from "features/PaymentLinks/modules/CreatePaymentLinkModal/constants/CreatePaymentLinkModal.constants";
import { generateLinkTypeOption } from "features/PaymentLinks/modules/CreatePaymentLinkModal/utils/CreatePaymentLinkModal.utils";

export const CHECKOUT_PAGE_LABELS = {
  [LINK_TYPES.CUSTOM_LINK]: "Fixed Checkout Page",
  [LINK_TYPES.OPEN_LINK]: "Flexible Checkout Page",
  [LINK_TYPES.UNIVERSAL_LINK]: "Universal Checkout Page",
};

export const CHECKOUT_PAGE_TYPE_OPTIONS = [
  generateLinkTypeOption({
    title: CHECKOUT_PAGE_LABELS[LINK_TYPES.OPEN_LINK],
    description: "Use to create links where your customers can pay any price",
    value: LINK_TYPES.OPEN_LINK,
    icon: LINK_TYPE_ICONS[LINK_TYPES.OPEN_LINK],
  }),
  generateLinkTypeOption({
    title: CHECKOUT_PAGE_LABELS[LINK_TYPES.CUSTOM_LINK],
    description:
      "Use to create custom payment links different than the original price",
    value: LINK_TYPES.CUSTOM_LINK,
    icon: LINK_TYPE_ICONS[LINK_TYPES.CUSTOM_LINK],
  }),
];

export const CREATE_CHECKOUT_LINK_MODAL_TITLES = {
  [LINK_TYPES.OPEN_LINK]: "Create Flexible Checkout Page",
  [LINK_TYPES.CUSTOM_LINK]: "Create Fixed Checkout Page",
};

export const checkout_update_route_key = "checkout_page_update_details";

export const checkout_page_listing_video = "https://youtu.be/S2owetnHu44";
