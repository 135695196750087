// @ts-ignore
import { checkout_page_button_types as _checkout_page_button_types } from "@my-scoot/exly-react-component-lib/build/cjs/features/CheckoutPages/constants/CheckoutPages.constants";
import { checkout_page_button_types as checkout_page_button_types_interface } from "@my-scoot/exly-react-component-lib/build/types/features/CheckoutPages/constants/CheckoutPages.constants";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { appendUrlParams } from "utils/urlUtils";

export const CHECKOUT_PAGE_ROUTE_KEYS = {
  checkout_page_listing: "checkout_page_listing",
  checkout_page_update_details: "update",
};

export const CHECKOUT_PAGE_ROUTE_IDS = {
  [CHECKOUT_PAGE_ROUTE_KEYS.checkout_page_listing]: "payment-pages/list",
  [CHECKOUT_PAGE_ROUTE_KEYS.checkout_page_update_details]:
    "/payment-page/update/:listingUuid",
};

export const CHECKOUT_PAGES_ROUTE_GENERATORS = {
  [CHECKOUT_PAGE_ROUTE_KEYS.checkout_page_update_details]: ({
    listingUuid = "",
    dynamicLinkUuid = "",
    isCheckout = false,
  }: {
    listingUuid: string;
    dynamicLinkUuid?: string;
    isCheckout?: boolean;
  }) =>
    appendUrlParams(`/payment-page/update/${listingUuid}`, {
      dynamic_link_uid: dynamicLinkUuid,
      ...(isCheckout && {
        isCheckout: true,
      }),
    }),
};

export const CHECKOUT_PAGE_RESOURCE_APIS = {
  [CHECKOUT_PAGE_ROUTE_IDS[CHECKOUT_PAGE_ROUTE_KEYS.checkout_page_listing]]:
    () => {
      return {
        api_end_point: "payments/exly/dynamiclinks/all",
      };
    },
};

// Checkout Page has a Payment button, and a button on mobile screen which opens payment modal
// By default the button on mobile is called 'Next' and the payment button
// is 'Pay <amount>'. This is the 'default' button type.
// In 'custom' button type we can update the text of these two buttons
export const checkout_page_button_types: typeof checkout_page_button_types_interface =
  _checkout_page_button_types;

export const default_user_image = getS3AssetCDNUrl(
  "assets/images/common/default-user.png"
);
