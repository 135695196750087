import constants from "constants/constants";

export const checkIsCheckoutEnabledListing = (listingData: {
  type: number;
}) => {
  const { scheduleTypesId } = constants;
  return [
    scheduleTypesId.no_schedule,
    scheduleTypesId.webinar,
    scheduleTypesId.content,
    scheduleTypesId.branded_community,
    scheduleTypesId.whatsapp,
    scheduleTypesId.workshop,
    scheduleTypesId.one_on_one_appointment,
    scheduleTypesId.group_class,
    scheduleTypesId.rolling_class,
    scheduleTypesId.one_on_one_class,
    scheduleTypesId.telegram,
    scheduleTypesId.merchandise,
  ].includes(listingData.type);
};
